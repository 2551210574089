<template>
    <div class="artice_top widthCen content">
        <h6>
            <span
                @click="$router.push('/article')"
                style="color:#333"
            >文章中心</span> >
            <span>文章详情</span>
        </h6>
        <div class="article_white">
            <div class="details">
                <h6>{{model.Title}}</h6>

                <div
                    class="details_box"
                    v-html="model.Content"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/artice.scss';
</style>
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            model: {
                Title: '',
                Content: ''
            }
        };
    },
    created() {
        var id = this.$route.params.id;
        this.getDetail(id);
    },
    methods: {
        details() {
            this.$router.push('./articleDetails');
        },
        getDetail(id) {
            this.$api.getArcitleDetail(id).then(res => {
                this.model = res.data;
                this.$nextTick(() => {
                    document.documentElement.scrollTop = document.body.scrollTop = 0;
                });
            });
        }
    }
};
</script>
